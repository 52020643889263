
  export default {
    props: {
      id: {
        type: String
      },

      tenant: {
        type: Object
      },

      leases: {
        type: Array
      },
    },

    data() {
      return {
      }
    },

    watch: {
      authenticated: {
        immediate: true,
        handler: async function (value) {
          if (value)
            await this.refresh();
        }
      },

      tenantId: {
        immediate: true,
        handler: async function (id) {
          this.$emit('update:id', id);
        }
      },

      currentTenant: {
        immediate: true,
        handler: function (value) {
          this.$emit('update:tenant', value);
        }
      },

      tenantLeases(value) {
        this.$emit('update:leases', value);
      },
    },

    computed: {
      authenticated() {
        return this.$store.getters['cognito/authenticated'];
      },

      tenantId() {
        return this.$store?.state?.tenant?.id;
      },

      tenantLeases() {
        return this.$store?.state?.tenant?.all;
      },

      currentTenant() {
        return this.tenantLeases.find(tl => tl.id === this.tenantId && tl.active);
      },
    },

    methods: {
      async refresh() {
        const { data } = await this.$axios.get(`/api/tenants/leases`);

        this.$store.commit('tenant/setAll', data ?? []);

        if (!this.tenantId && this.tenantLeases.length > 0)
          this.change(this.tenantLeases[0].id);
      },

      change(id, redirect) {
        this.$store.commit('tenant/setId', id);
        redirect ? this.$router.push(redirect) : this.$router.go();
      },
    }
  }
