
export default {
  data() {
    return {
      drawerOpen: undefined
    };
  },

  created() {
    let dark = this.$store.state.app.dark;

    if (dark === undefined) {
      dark = localStorage.getItem("dark") ?? (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);
      dark = dark === true || dark === "true";
      this.$store.commit("app/setDarkScheme", dark);
    }

    this.$vuetify.theme.dark = dark;
  },

  methods: {
    toggleDrawer() {
      this.drawerOpen = false;
    },

    toggleDarkMode() {
      const dark = !this.$store.state.app.dark;

      this.$store.commit("app/setDarkScheme", dark);
      this.$vuetify.theme.dark = dark;
    }
  }
};
