import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a95cd35e = () => interopDefault(import('..\\pages\\endpoints\\index.vue' /* webpackChunkName: "pages/endpoints/index" */))
const _2399aec9 = () => interopDefault(import('..\\pages\\inbox\\index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _73846aa6 = () => interopDefault(import('..\\pages\\integrations\\index.vue' /* webpackChunkName: "pages/integrations/index" */))
const _9b103360 = () => interopDefault(import('..\\pages\\push-notifications\\index.vue' /* webpackChunkName: "pages/push-notifications/index" */))
const _6e669c66 = () => interopDefault(import('..\\pages\\remotemonitors\\index.vue' /* webpackChunkName: "pages/remotemonitors/index" */))
const _4b2a06a0 = () => interopDefault(import('..\\pages\\reports\\index.vue' /* webpackChunkName: "pages/reports/index" */))
const _0dba89c2 = () => interopDefault(import('..\\pages\\signin.vue' /* webpackChunkName: "pages/signin" */))
const _628cb0ea = () => interopDefault(import('..\\pages\\tokens\\index.vue' /* webpackChunkName: "pages/tokens/index" */))
const _22baebb1 = () => interopDefault(import('..\\pages\\unauthorized.vue' /* webpackChunkName: "pages/unauthorized" */))
const _8421db96 = () => interopDefault(import('..\\pages\\landlord\\clear-cache.vue' /* webpackChunkName: "pages/landlord/clear-cache" */))
const _092e3ede = () => interopDefault(import('..\\pages\\remotemonitors\\setup.vue' /* webpackChunkName: "pages/remotemonitors/setup" */))
const _3a036df4 = () => interopDefault(import('..\\pages\\services\\wizard.vue' /* webpackChunkName: "pages/services/wizard" */))
const _134839b6 = () => interopDefault(import('..\\pages\\settings\\directory\\index.vue' /* webpackChunkName: "pages/settings/directory/index" */))
const _3ce525ee = () => interopDefault(import('..\\pages\\settings\\tenants\\index.vue' /* webpackChunkName: "pages/settings/tenants/index" */))
const _4270d8aa = () => interopDefault(import('..\\pages\\settings\\users\\index.vue' /* webpackChunkName: "pages/settings/users/index" */))
const _157ab94c = () => interopDefault(import('..\\pages\\settings\\directory\\import\\psa.vue' /* webpackChunkName: "pages/settings/directory/import/psa" */))
const _4dd39eef = () => interopDefault(import('..\\pages\\flows\\designer\\canvas\\_id.vue' /* webpackChunkName: "pages/flows/designer/canvas/_id" */))
const _a9ea5c72 = () => interopDefault(import('..\\pages\\flows\\designer\\_id.vue' /* webpackChunkName: "pages/flows/designer/_id" */))
const _d53b2a66 = () => interopDefault(import('..\\pages\\settings\\directory\\_id.vue' /* webpackChunkName: "pages/settings/directory/_id" */))
const _7a751db1 = () => interopDefault(import('..\\pages\\settings\\tenants\\_id.vue' /* webpackChunkName: "pages/settings/tenants/_id" */))
const _fa5e04dc = () => interopDefault(import('..\\pages\\settings\\users\\_id.vue' /* webpackChunkName: "pages/settings/users/_id" */))
const _3f68d7c4 = () => interopDefault(import('..\\pages\\activity\\_id.vue' /* webpackChunkName: "pages/activity/_id" */))
const _ff7a0e0e = () => interopDefault(import('..\\pages\\endpoints\\_id.vue' /* webpackChunkName: "pages/endpoints/_id" */))
const _7658de8e = () => interopDefault(import('..\\pages\\integrations\\_id.vue' /* webpackChunkName: "pages/integrations/_id" */))
const _98c6ff64 = () => interopDefault(import('..\\pages\\remotemonitors\\_id.vue' /* webpackChunkName: "pages/remotemonitors/_id" */))
const _1b1210b3 = () => interopDefault(import('..\\pages\\tokens\\_id.vue' /* webpackChunkName: "pages/tokens/_id" */))
const _ae594e86 = () => interopDefault(import('..\\pages\\reports\\_.vue' /* webpackChunkName: "pages/reports/_" */))
const _1272fff7 = () => interopDefault(import('..\\pages\\flows\\_.vue' /* webpackChunkName: "pages/flows/_" */))
const _2bfed036 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _61368dca = () => interopDefault(import('~/pages/flows/_.vue' /* webpackChunkName: "" */))
const _2563ddd0 = () => interopDefault(import('~/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/endpoints",
    component: _a95cd35e,
    name: "endpoints"
  }, {
    path: "/inbox",
    component: _2399aec9,
    name: "inbox"
  }, {
    path: "/integrations",
    component: _73846aa6,
    name: "integrations"
  }, {
    path: "/push-notifications",
    component: _9b103360,
    name: "push-notifications"
  }, {
    path: "/remotemonitors",
    component: _6e669c66,
    name: "remotemonitors"
  }, {
    path: "/reports",
    component: _4b2a06a0,
    name: "reports"
  }, {
    path: "/signin",
    component: _0dba89c2,
    name: "signin"
  }, {
    path: "/tokens",
    component: _628cb0ea,
    name: "tokens"
  }, {
    path: "/unauthorized",
    component: _22baebb1,
    name: "unauthorized"
  }, {
    path: "/landlord/clear-cache",
    component: _8421db96,
    name: "landlord-clear-cache"
  }, {
    path: "/remotemonitors/setup",
    component: _092e3ede,
    name: "remotemonitors-setup"
  }, {
    path: "/services/wizard",
    component: _3a036df4,
    name: "services-wizard"
  }, {
    path: "/settings/directory",
    component: _134839b6,
    name: "settings-directory"
  }, {
    path: "/settings/tenants",
    component: _3ce525ee,
    name: "settings-tenants"
  }, {
    path: "/settings/users",
    component: _4270d8aa,
    name: "settings-users"
  }, {
    path: "/settings/directory/import/psa",
    component: _157ab94c,
    name: "settings-directory-import-psa"
  }, {
    path: "/flows/designer/canvas/:id?",
    component: _4dd39eef,
    name: "flows-designer-canvas-id"
  }, {
    path: "/flows/designer/:id?",
    component: _a9ea5c72,
    name: "flows-designer-id"
  }, {
    path: "/settings/directory/:id",
    component: _d53b2a66,
    name: "settings-directory-id"
  }, {
    path: "/settings/tenants/:id",
    component: _7a751db1,
    name: "settings-tenants-id"
  }, {
    path: "/settings/users/:id",
    component: _fa5e04dc,
    name: "settings-users-id"
  }, {
    path: "/activity/:id?",
    component: _3f68d7c4,
    name: "activity-id"
  }, {
    path: "/endpoints/:id",
    component: _ff7a0e0e,
    name: "endpoints-id"
  }, {
    path: "/integrations/:id",
    component: _7658de8e,
    name: "integrations-id"
  }, {
    path: "/remotemonitors/:id",
    component: _98c6ff64,
    name: "remotemonitors-id"
  }, {
    path: "/tokens/:id",
    component: _1b1210b3,
    name: "tokens-id"
  }, {
    path: "/reports/*",
    component: _ae594e86,
    name: "reports-all"
  }, {
    path: "/flows/*",
    component: _1272fff7,
    name: "flows-all"
  }, {
    path: "/",
    component: _2bfed036,
    name: "index"
  }, {
    path: "/flows",
    component: _61368dca,
    name: "flows-no-trailing-slash"
  }, {
    path: "/:preset(recent|priority|today|week)?",
    component: _2563ddd0,
    name: "dashboard"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
