
import debounce from "lodash/debounce";

export default {
	created() {
		this.extensionContentChanged = debounce(this.extensionContentChanged, 500);
	},

	data() {
		return {
			extended: true,
		};
	},

	computed: {
		extensionHeight() {
			return this.extended ? undefined : 0;
		},
	},
	methods: {
		toggleDrawer() {
			this.$store.commit("app/setDrawer", !this.$store.state.app.drawer);
		},

		extensionContentChanged(value) {
			this.extended = !!value;
		},
	},
};
