import { render, staticRenderFns } from "./drawer.vue?vue&type=template&id=41ccf9b8&scoped=true&"
import script from "./drawer.vue?vue&type=script&lang=js&"
export * from "./drawer.vue?vue&type=script&lang=js&"
import style0 from "./drawer.vue?vue&type=style&index=0&id=41ccf9b8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ccf9b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TenantManager: require('C:/Visual Studio .NET Projects/United Datacom Networks/Vigilance 2.0/Vigilance/Vigilance.UI/components/tenant-manager.vue').default,ConfirmDialog: require('C:/Visual Studio .NET Projects/United Datacom Networks/Vigilance 2.0/Vigilance/Vigilance.UI/components/confirm-dialog.vue').default})
