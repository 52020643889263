export default (context) => {
  context.$axios.onRequest(async config => {
    await context.store.dispatch('cognito/refresh');
    const token = context.store.getters['cognito/token'];
    const leaseId = context.store.state.tenant.id;

    if (leaseId)
      config.headers['use-lease-id'] = leaseId;

    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
}
