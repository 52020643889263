export const state = () => (
  {
    drawer: undefined,
    dark: true, /*localStorage.getItem('dark') ? localStorage.getItem('dark') === 'true' : undefined,*/
  }
)

export const mutations = {
  setDrawer(state, value) {
    state.drawer = value;
  },

  setDarkScheme(state, value) {
    state.dark = value;
    localStorage.setItem('dark', value);
  },
}
