import Amplify, { Hub } from 'aws-amplify'
import config from '~/aws-exports'
import '@aws-amplify/ui-vue';

export default ({ store }) => {
  const hubListener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        store.dispatch('cognito/refresh');
        break;

      case 'signUp':
        store.dispatch('cognito/refresh');
        break;

      case 'signOut':
        store.commit('cognito/clearState');
        break;

      case 'signIn_failure':
        store.commit('cognito/clearState');
        break;

      case 'tokenRefresh':
        store.dispatch('cognito/refresh');
        break;

      case 'tokenRefresh_failure':
        store.commit('cognito/clearState');
        break;

      case 'configured':
        store.dispatch('cognito/refresh');
    }
  }

  Hub.listen('auth', hubListener);
  Amplify.configure(config)
}
