import { Auth } from "aws-amplify";
import { isEqual } from "lodash";

const useIdentityToken = true;

export const state = () => ({
	user: null,
	session: null,
});

export const getters = {
	authenticated: state => !!state.user,
	token: state => (useIdentityToken ? state.session?.idToken?.jwtToken : state.session?.accessToken?.jwtToken),
	roles: state =>
		useIdentityToken
			? state.session?.idToken?.payload["cognito:groups"]
			: state.session?.accessToken?.payload["cognito:groups"],

	inRole: state => (...roles) => {
		if (!state.user) return false;

		const payload = useIdentityToken ? state.session?.idToken?.payload : state.session?.accessToken?.payload;
		return payload["cognito:groups"]?.some(g => roles.includes(g)) ?? false;
	},

	inEveryRole: state => (...roles) => {
		if (!state.user) return false;

		const payload = useIdentityToken ? state.session?.idToken?.payload : state.session?.accessToken?.payload;
		return roles.every(r => payload["cognito:groups"]?.includes(r)) ?? false;
	},
};

export const mutations = {
	setUserSession(state, { attributes, signInUserSession }) {
		state.user = attributes;
		state.session = signInUserSession;
	},

	clearState(state) {
		state.user = null;
		state.session = null;
	},
};

export const actions = {
	async refresh(context) {
		try {
			const { attributes, signInUserSession } = await Auth.currentAuthenticatedUser();

			if (!isEqual(context.state.user, attributes) || !isEqual(context.state.session, signInUserSession)) {
				context.commit("setUserSession", { attributes, signInUserSession });
			}
		} catch (e) {
			console.error("error in store refresh", e);
		}
	},
};
