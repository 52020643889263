
import lottie from "lottie-web";

export default {
	props: {
		animation: {
			type: String,
			required: true,
		},

		title: {
			type: String,
		},

		absolute: {
			type: Boolean,
			default: false,
		},

		size: {
			type: [String, Number],
			default: "33vh",
		},

		fade: {
			type: [String, Number],
			default: "1",
		},
	},

	watch: {
		animation(value) {
			this.load(value);
		},
	},

	mounted() {
		this.load(this.animation);
	},

	methods: {
		load(filename) {
			let lottieFile = filename;

			if (!lottieFile.endsWith(".json")) lottieFile += ".json";

			lottie.destroy();

			lottie.loadAnimation({
				container: document.getElementById("lottie"),
				renderer: "svg",
				loop: true,
				autoplay: true,
				path: `/lottie/${lottieFile}`,
			});
		},
	},
};
