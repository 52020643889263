export default async function ({ store, route, redirect }) {
  const allowAnonymous = (route.meta[0] && route.meta[0].allowAnonymous) ?? false;
  const roles = (route.meta[0] && route.meta[0].roles);

  // anonymous routes are always allowed
  if (allowAnonymous) return;

  // refresh the session
  await store.dispatch('cognito/refresh');

  // otherwise, the user must at least be authenticated
  if (!store.getters['cognito/authenticated']) {
    return redirect('/signin');
  }

  // successful if route has no additional role requirements
  if (!roles) return;

  // role-based authorization
  const valid = store.getters['cognito/inRole'](...roles);

  if (valid) {
    return;
  } else {
    return redirect('/unauthorized');
  }
}
