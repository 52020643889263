
  export default {
    props: {
      error: {
        type: Object,
        default: null
      }
    },

    computed: {
      animation() {
        return this.error.statusCode === 404 ? '404.json' : 'database.json';
      }
    },
  }
