export const state = () => (
  {
    all: [],
    id: localStorage.getItem("tenant:id") ?? undefined,
  }
)

export const mutations = {
  setAll(state, value) {
    state.all = value;
  },

  setId(state, value) {
    state.id = value;
    localStorage.setItem('tenant:id', value);
  }
}
