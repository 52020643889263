export const Bar = () => import('../..\\components\\layout\\bar.vue' /* webpackChunkName: "components/bar" */).then(c => wrapFunctional(c.default || c))
export const DetailsPage = () => import('../..\\components\\layout\\details-page.vue' /* webpackChunkName: "components/details-page" */).then(c => wrapFunctional(c.default || c))
export const Drawer = () => import('../..\\components\\layout\\drawer.vue' /* webpackChunkName: "components/drawer" */).then(c => wrapFunctional(c.default || c))
export const PortalBar = () => import('../..\\components\\layout\\portal-bar.vue' /* webpackChunkName: "components/portal-bar" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../..\\components\\layout\\search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const ActivityCard = () => import('../..\\components\\dashboard\\activity-card.vue' /* webpackChunkName: "components/activity-card" */).then(c => wrapFunctional(c.default || c))
export const BoardTable = () => import('../..\\components\\dashboard\\board-table.vue' /* webpackChunkName: "components/board-table" */).then(c => wrapFunctional(c.default || c))
export const DesignerCanvas = () => import('../..\\components\\designer\\canvas.vue' /* webpackChunkName: "components/designer-canvas" */).then(c => wrapFunctional(c.default || c))
export const DesignerHtmlEditorVariableBlot = () => import('../..\\components\\designer\\html-editor-variable-blot.js' /* webpackChunkName: "components/designer-html-editor-variable-blot" */).then(c => wrapFunctional(c.default || c))
export const DesignerHtmlEditor = () => import('../..\\components\\designer\\html-editor.vue' /* webpackChunkName: "components/designer-html-editor" */).then(c => wrapFunctional(c.default || c))
export const DesignerNode = () => import('../..\\components\\designer\\node.vue' /* webpackChunkName: "components/designer-node" */).then(c => wrapFunctional(c.default || c))
export const DesignerParamsConfiguration = () => import('../..\\components\\designer\\params-configuration.vue' /* webpackChunkName: "components/designer-params-configuration" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsBase = () => import('../..\\components\\designer\\configurations\\base.vue' /* webpackChunkName: "components/designer-configurations-base" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurations = () => import('../..\\components\\designer\\configurations\\index.js' /* webpackChunkName: "components/designer-configurations" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsTEMPLATE = () => import('../..\\components\\designer\\configurations\\TEMPLATE.vue' /* webpackChunkName: "components/designer-configurations-t-e-m-p-l-a-t-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerMailCreateMailFilters = () => import('../..\\components\\designer\\mail\\create-mail-filters.vue' /* webpackChunkName: "components/designer-mail-create-mail-filters" */).then(c => wrapFunctional(c.default || c))
export const DesignerMailSampleDisplay = () => import('../..\\components\\designer\\mail\\sample-display.vue' /* webpackChunkName: "components/designer-mail-sample-display" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTAPI = () => import('../..\\components\\designer\\configurations\\actions\\ACT_API.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-a-p-i" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTCHANNELMSG = () => import('../..\\components\\designer\\configurations\\actions\\ACT_CHANNEL_MSG.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-c-h-a-n-n-e-l-m-s-g" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTCODE = () => import('../..\\components\\designer\\configurations\\actions\\ACT_CODE.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-c-o-d-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTCONNECTWISE = () => import('../..\\components\\designer\\configurations\\actions\\ACT_CONNECTWISE.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-c-o-n-n-e-c-t-w-i-s-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTEMAIL = () => import('../..\\components\\designer\\configurations\\actions\\ACT_EMAIL.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-e-m-a-i-l" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTGLASS = () => import('../..\\components\\designer\\configurations\\actions\\ACT_GLASS.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-g-l-a-s-s" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTPUSH = () => import('../..\\components\\designer\\configurations\\actions\\ACT_PUSH.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-p-u-s-h" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsActionsACTSMS = () => import('../..\\components\\designer\\configurations\\actions\\ACT_SMS.vue' /* webpackChunkName: "components/designer-configurations-actions-a-c-t-s-m-s" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINAWSEVENTBRIDGE = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_AWS_EVENTBRIDGE.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-a-w-s-e-v-e-n-t-b-r-i-d-g-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINENDPOINT = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_ENDPOINT.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-e-n-d-p-o-i-n-t" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINMAIL = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_MAIL.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-m-a-i-l" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINMONITOR = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_MONITOR.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-m-o-n-i-t-o-r" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINSCHEDULE = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_SCHEDULE.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-s-c-h-e-d-u-l-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsOriginsORIGINVIGILANCE = () => import('../..\\components\\designer\\configurations\\origins\\ORIGIN_VIGILANCE.vue' /* webpackChunkName: "components/designer-configurations-origins-o-r-i-g-i-n-v-i-g-i-l-a-n-c-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCDAY = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_DAY.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-d-a-y" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCEVENT = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_EVENT.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-e-v-e-n-t" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCFREQ = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_FREQ.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-f-r-e-q" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCHIBERNATE = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_HIBERNATE.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-h-i-b-e-r-n-a-t-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCTIME = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_TIME.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-t-i-m-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCVARIABLE = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_VARIABLE.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-v-a-r-i-a-b-l-e" */).then(c => wrapFunctional(c.default || c))
export const DesignerConfigurationsFunctionsFUNCWAIT = () => import('../..\\components\\designer\\configurations\\functions\\FUNC_WAIT.vue' /* webpackChunkName: "components/designer-configurations-functions-f-u-n-c-w-a-i-t" */).then(c => wrapFunctional(c.default || c))
export const AToken = () => import('../..\\components\\a-token.vue' /* webpackChunkName: "components/a-token" */).then(c => wrapFunctional(c.default || c))
export const AvatarEditor = () => import('../..\\components\\avatar-editor.vue' /* webpackChunkName: "components/avatar-editor" */).then(c => wrapFunctional(c.default || c))
export const Clipboard = () => import('../..\\components\\clipboard.vue' /* webpackChunkName: "components/clipboard" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../..\\components\\confirm-dialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const FlowIcon = () => import('../..\\components\\flow-icon.vue' /* webpackChunkName: "components/flow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconPicker = () => import('../..\\components\\icon-picker.vue' /* webpackChunkName: "components/icon-picker" */).then(c => wrapFunctional(c.default || c))
export const Lottie = () => import('../..\\components\\lottie.vue' /* webpackChunkName: "components/lottie" */).then(c => wrapFunctional(c.default || c))
export const ModalDialog = () => import('../..\\components\\modal-dialog.vue' /* webpackChunkName: "components/modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const Pdf = () => import('../..\\components\\pdf.vue' /* webpackChunkName: "components/pdf" */).then(c => wrapFunctional(c.default || c))
export const RemoveDialog = () => import('../..\\components\\remove-dialog.vue' /* webpackChunkName: "components/remove-dialog" */).then(c => wrapFunctional(c.default || c))
export const ReportingPeriodSelector = () => import('../..\\components\\reporting-period-selector.vue' /* webpackChunkName: "components/reporting-period-selector" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../..\\components\\spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const TenantManager = () => import('../..\\components\\tenant-manager.vue' /* webpackChunkName: "components/tenant-manager" */).then(c => wrapFunctional(c.default || c))
export const TimeoutBtn = () => import('../..\\components\\timeout-btn.vue' /* webpackChunkName: "components/timeout-btn" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
