
  export default {
    name: 'confirm-dialog',

    props: {
      contentClass: {
        type: String,
      }
    },

    data() {
      return {
        visible: false,
        promise: { resolve: undefined, reject: undefined },
        title: '',
        body: '',
        buttons: [],
        validationCallback: undefined,
      }
    },

    watch: {
      visible: async function (value) {
        await this.$nextTick();
        setTimeout(() => this.$emit(value ? 'shown' : 'hidden'), 250);
      }
    },

    computed: {
      maxWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '85vw' : this.$vuetify.breakpoint.mdAndDown ? '50vw' : '33vw';
      }
    },

    methods: {
      show(title, body, buttons, validationCallback) {
        this.title = title;
        this.body = body;
        this.validationCallback = validationCallback;

        if (!buttons) {
          buttons = 'OK';
        }

        if (typeof buttons === 'string') {
          this.buttons = buttons.split(',').map(b => ({ text: b.toUpperCase().trim(), value: b.trim() }));
        }

        if (Array.isArray(buttons)) {
          this.buttons = buttons.map(b => {
            if (typeof b === 'string') {
              return { text: b, value: b };
            } else {
              return { text: b.text, value: b.value || b.text, icon: b.icon, color: b.color };
            }
          });
        }

        this.visible = true;

        return new Promise((resolve, reject) => {
          this.promise.resolve = resolve;
          this.promise.reject = reject;
        });
      },

      selected(action) {
        if (this.validationCallback) {
          const valid = this.validationCallback(action);

          if (!valid)
            return;
        }

        this.promise.resolve(action);
        this.visible = false;
      }
    }
  }
