
	import { Auth } from "aws-amplify";

	export default {
		data() {
			return {
				tenantId: undefined,
				tenant: undefined,
				tenantLeases: [],
				visible: undefined,
				user: undefined,
				search: undefined,
			};
		},

		watch: {
			drawer: function(value) {
				this.visible = value;
			},

			visible: function(value) {
				this.$store.commit("app/setDrawer", value);
			},

			authenticated: {
				immediate: true,
				handler: function(value) {
					if (value) {
						this.getUser();
						this.visible = undefined;
					} else {
						this.visible = false;
						this.$router.push("/signin");
					}
				},
			},
		},

		computed: {
			authenticated() {
				return this.$store.getters["cognito/authenticated"];
			},

			drawer() {
				return this.$store.state.app.drawer;
			},

			darkmode() {
				return this.$store.state.app.dark;
			},

			filteredLeases() {
				if (!this.search) return this.tenantLeases;

				const search = this.search?.trim().toLowerCase();

				return this.tenantLeases.filter(
					(l) => l.name.toLowerCase().includes(search) || l.shortCode.toLowerCase().includes(search)
				);
			},

			links() {
				const links = [
					{
						subheader: "Flow Alarms",
					},

					{
						name: "alarms-stream",
						icon: "fa-signal-stream",
						title: "Live Stream",
						to: "/",
					},

					{
						name: "alarms-recent",
						icon: "fa-stopwatch",
						title: "Most Recent",
						to: "/recent",
						exact: true,
					},

					{
						name: "alarms-priority",
						icon: "fa-calendar-exclamation",
						title: "High Priority",
						to: "/priority",
						exact: true,
					},

					{
						name: "alarms-today",
						icon: "fa-calendar-day",
						title: "Today",
						to: "/today",
						exact: true,
					},

					{
						name: "alarms-week",
						icon: "fa-calendar-week",
						title: "This Week",
						to: "/week",
						exact: true,
					},

					{
						name: "push-notifications",
						icon: "fa-phone-laptop",
						title: "Push Notifications",
						to: "/push-notifications",
					},

					{
						subheader: "Flows Configuration",
						roles: ["designer", "administrator"],
					},

					{
						name: "designer",
						icon: "fa-pencil-ruler",
						title: "Flows",
						to: "/flows",
						roles: ["designer", "administrator"],
					},

					{
						name: "wizard",
						icon: "fa-magic",
						title: "Common Services Wizard",
						to: "/services/wizard",
						roles: ["designer", "administrator"],
					},

					{
						name: "settings-tokens",
						icon: "fa-key",
						title: "Access Tokens",
						to: "/tokens",
						roles: ["designer", "administrator"],
					},

					{
						name: "settings-api",
						icon: "fa-cogs",
						title: "API Endpoints",
						to: "/endpoints",
						roles: ["designer", "administrator"],
					},

					{
						name: "settings-inbox",
						icon: "fa-inbox-in",
						title: "Inbox Browser",
						to: "/inbox",
						roles: ["designer", "administrator"],
					},

					{
						name: "settings-integrations",
						icon: "fa-puzzle-piece",
						title: "Integrations",
						to: "/integrations",
						roles: ["designer", "administrator"],
					},

					{
						name: "remote-monitors",
						icon: "fa-monitor-heart-rate",
						title: "Remote Monitors",
						to: "/remotemonitors",
						roles: ["designer", "administrator"],
					},

					{
						subheader: "Tenant Administration",
						roles: ["administrator"],
					},

					{
						name: "settings-reporting",
						icon: "fa-file-chart-line",
						title: "Reports",
						to: "/reports",
						roles: ["administrator"],
					},

					{
						name: "settings-tenants",
						icon: "fa-user-shield",
						title: "Tenant Leases",
						to: "/settings/tenants",
						roles: ["administrator"],
					},

					{
						name: "settings-users",
						icon: "fa-users",
						title: "Users",
						to: "/settings/users",
						roles: ["administrator"],
					},

					{
						name: "settings-directory",
						icon: "fa-address-book",
						title: "Contact Directory",
						to: "/settings/directory",
						roles: ["administrator"],
					},

					{
						name: "settings-rm",
						icon: "fa-monitor-heart-rate",
						title: "Remote Monitor Setup",
						to: "/remotemonitors/setup",
						roles: ["administrator"],
						exact: true,
					},

					{
						subheader: "Landlords",
						roles: ["landlord"],
					},

					{
						name: "jobs",
						icon: "fa-chart-area",
						title: "Background Jobs",
						url: `${this.$config.hubURL}/background-jobs`,
						roles: ["landlord"],
					},

					{
						name: "cache",
						icon: "fa-box-alt",
						title: "Clear Cache",
						to: `/landlord/clear-cache`,
						roles: ["landlord"],
					},
				];

				return links.filter((l) => {
					if (l.children) l.children = l.children.filter((c) => !c.roles || this.role(c.roles));

					return !l.roles || this.role(l.roles);
				});
			},
		},

		created() {
			window.FontAwesomeConfig.autoReplaceSvg = "nest";
		},

		methods: {
			role(roles) {
				return !roles || this.$store.getters["cognito/inRole"](...roles);
			},

			linkInRoute(value) {
				return value.exact ? value.to === this.$route.fullPath : this.$route.path.startsWith(value.to);
			},

			toggleDarkMode() {
				this.$emit("toggle-dark-mode");
			},

			async linkClicked(event, link) {
				if (link.name === "jobs") {
					event.preventDefault();
					event.stopPropagation();

					await this.$store.dispatch("cognito/refresh");
					const token = this.$store.getters["cognito/token"];

					window.location.href = `${link.url}/?access_token=${token}`;
				}
			},

			async getUser() {
				const email = this.$store.state.cognito?.user?.email;

				try {
					if (!email) {
						this.user = undefined;
						return;
					}

					const { data: users } = await this.$axios.get(`/api/tenants/users/${email}`);
					this.user = users;
				} catch (e) {
					console.error(e);
					this.user = undefined;
				}
			},

			changeTenant(id, redirect) {
				this.$refs.tenantManager.change(id);
			},

			async signout() {
				const response = await this.$refs.confirmDialog.show(
					"Sign Out",
					"Are you sure you would like to sign out of Vigilance Flow?",
					["CANCEL", "SIGN OUT"]
				);

				if (response === "SIGN OUT") {
					this.user = undefined;
					//this.lease = undefined;
					await Auth.signOut();
				}
			},
		},
	};
