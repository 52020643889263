/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:de060ad3-b4f6-4bc3-a7f5-0e1169260d08",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hPknhgFD8",
    "aws_user_pools_web_client_id": "6i3kb1atshqp887anpb0jmggmj",
    "oauth": {}
};

export default awsmobile;
